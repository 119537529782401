label {
  font-weight: 500;
}

input {
  height: 36px;
  padding: 0 10px;
  border: 1px solid transparent;
  background: #f7f7f7;
  font-weight: 500;
  line-height: 36px;
  color: #4a4a49;
  transition: border-color 0.15s ease-in-out;
}

input:focus {
  outline: 0;
  border-color: #ef7026;
}

:not(output):-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-input-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}
::-moz-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}
:-ms-input-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}
:-moz-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}

input[type='checkbox'],
input[type='radio'] {
  height: 1px;
  left: -10000px;
  position: absolute;
  width: 1px;
  zoom: 1;
  filter: alpha(opacity=0);
  opacity: 0;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  position: relative;
  padding-left: 3rem;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  content: '';
  border: 1px solid #ef7026;
  background: transparent;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  transition: background-color 0.15s ease-in-out;
}

input[type='checkbox']:focus + label:before,
input[type='checkbox'] + label:hover:before,
input[type='radio']:focus + label:before,
input[type='radio'] + label:hover:before {
  border-width: 2px;
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  background: #ef7026;
}

input[type='radio'] + label:before {
  border-radius: 100%;
}

input:read-only,
textarea:read-only,
[contenteditable]:read-only {
  background: #fafafa;
  cursor: not-allowed;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

/* Suppress focus ring on buttons for mouse users */
[data-whatintent='mouse'] button:focus {
  outline: 0;
}

legend {
  margin-bottom: 2rem;
}

.RadioGroup .Radio {
  margin-bottom: 1rem;
}

.FormSection {
  position: relative;
  padding: 2rem 0;
  border: 0;
}

.FormSection + .FormSection {
  padding-top: 3rem;
  border-top: 1px dashed #ef7026;
}

.FormSection + .FormSection:before {
  content: '';
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: block;
  left: calc(50% - 1rem);
  top: -1rem;
  background: #fff;
  border-right: 1px dashed #ef7026;
  border-bottom: 1px dashed #ef7026;
  transform: rotate(45deg);
}

.FormSection legend {
  margin-left: -1rem;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 2rem;
}

.FormSection legend .Alert {
  display: inline-block;
}

.Form-buttongroup {
  float: right;
  text-align: right;
}

.Form-buttongroup .Button {
  margin-left: 1rem;
}

.Form-button {
  float: right;
  margin-left: 1rem;
}
