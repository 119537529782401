.Section--hints {
  display: flex;
}

.Section--hints > div {
  flex: 1;
}

@media (min-width: 768px) {
  .Section--hints > div:first-child {
    flex: 2;
  }
}
