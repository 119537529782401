.FundList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.FundList li {
  margin-bottom: 1rem;
  font-weight: 400;
}

.FundList li .bullet {
  margin-right: 5px;
}

.SelectedFunds .FundList li {
  position: relative;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  transition: background-color 0.2s ease-out;
}

.SelectedFunds .FundList li:hover {
  background-color: #fde6d9;
}

.Button--remove {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.Button--remove .Cross svg {
  width: auto;
  height: 1.5rem;
  color: #ef7026;
}

.FundList li:hover .Button--remove {
  opacity: 0.75;
}

.FundList li:hover .Button--remove:hover {
  opacity: 1;
}

.FundSearchResult {
  margin: 2rem 0;
}

.FundSearchResult h3 {
  margin-bottom: 1em;
}

.Select-sunsuper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Select-sunsuper .Button {
  margin-right: 2rem;
}

.Select-sunsuper .ReverseCallout {
  max-width: 40rem;
}
