table {
  width: 100%;
  /* table-layout: fixed; */
  margin: 0;
  background: #fff;
  border-collapse: collapse;
  line-height: 1.375;
}

table caption,
table th {
  text-align: left;
}

table th,
table td {
  vertical-align: middle;
  border-right: 1px solid #f79d69;
}

table th {
  padding: 3rem 1.5rem 1.5rem;
  background: #f7f7f7;
  border-bottom: 2px solid #f79d69;
}

table th:first-child {
  border-right: 2px solid #f79d69;
}

table td {
  padding: 1.5rem;
  border-bottom: 1px solid #f79d69;
}

table td:first-child {
  background: #f7f7f7;
  border-right-width: 2px;
}

table tr:last-child td {
  border-bottom: 0;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}

.Table--employers th {
  font-weight: 400;
}

.Table--employers .Tick {
  color: #f79d69;
}

.Table--employers ul,
.Table--employers dl {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.2;
}

.Table--employers ul li {
  margin-bottom: 1rem;
}

.Table--employers dt,
.Table--employers dd {
  display: inline-block;
}

.Table--employers dt {
  font-weight: 400;
}

.Table--employers td:nth-child(4),
.Table--employers td:nth-child(5) {
  text-align: center;
}

.Table--terms-acceptance {
  table-layout: fixed;
  margin: 3rem 0;
}

.Table--terms-acceptance .Button--select-all {
  color: #ef7026;
}
