.Button {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  line-height: 1;
  text-decoration: none;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
}

.Button:hover {
  cursor: pointer;
}

.Button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.Button--primary,
.Button--secondary {
  text-transform: uppercase;
  padding: 1rem 3rem;
  border-radius: 2rem;
  font-weight: 500;
  border: 2px solid;
}

.Button--primary {
  background: #ef7026;
  border-color: #ef7026;
  color: #fff;
}

.Button--primary:not(.Button--hollow):not(:disabled):hover {
  background-color: #f58544;
  border-color: #f58544;
}

.Button--secondary {
  background: #163655;
  border-color: #163655;
  color: #fff;
}

.Button--hollow {
  background-color: #fff;
}

.Button--primary.Button--hollow {
  color: #ef7026;
}

.Button--primary.Button--hollow:hover {
  color: #f58544;
  border-color: #f58544;
}

.Button--secondary.Button--hollow {
  color: #163655;
}

.Button--underline {
  text-decoration: underline;
  letter-spacing: 0.5px;
  color: #ef7026;
}

.Button--prev,
.Button--next {
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
}

.Button--prev:not(:disabled):before,
.Button--next:not(:disabled):after {
  position: absolute;
  top: 50%;
  opacity: 0;
  width: 1rem;
  height: 1rem;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transition: all 0.2s;
}

.Button--prev:before {
  content: '';
  left: 0;
  border-color: #ef7026;
  transform: translate(25px, -60%) rotate(135deg);
}

.Button--prev:hover:before,
.Button--prev:focus:before {
  transform: translate(15px, -60%) rotate(135deg);
}

.Button--next:after {
  content: '';
  right: 0;
  border-color: #fff;
  transform: translate(-25px, -60%) rotate(-45deg);
}

.Button--next:hover:after,
.Button--next:focus:after {
  transform: translate(-15px, -60%) rotate(-45deg);
}

.Button--prev:hover:before,
.Button--prev:focus:before,
.Button--next:hover:after,
.Button--next:focus:after {
  opacity: 1;
}

.Button--prev span,
.Button--next span {
  display: block;
  transition: inherit;
}

.Button--prev:not(:disabled):hover span,
.Button--prev:not(:disabled):focus span {
  transform: translateX(5px);
}
.Button--next:not(:disabled):hover span,
.Button--next:not(:disabled):focus span {
  transform: translateX(-5px);
}
