.FormGroup {
  margin-bottom: 1.5rem;
}

.FormGroup > * {
  display: block;
}

.Form-required {
  color: #ee3d48;
}

.Form-hint {
  display: block;
  font-size: 1.3rem;
  font-weight: 300;
}

.FormGroup > .Select,
.FormGroup > input {
  width: 100%;
}

.FormGroup > input.has-error {
  border-color: #ee3d48;
}

.Form-error {
  margin-top: 0.5rem;
  color: #ee3d48;
  font-weight: 400;
  font-size: 1.4rem;
}

.FormGroup + .bsbInfo {
  margin: -1rem 0 2rem;
  font-weight: 400;
  font-size: 1.4rem;
}
