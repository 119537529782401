.Accordion {
  margin: 1rem 0 2rem;
  border-bottom: 1px solid #ef7026;
  background-color: #f7f7f7;
}

.Accordion-trigger {
  background: none;
  border: 0;
  display: block;
  font-weight: 500;
  margin: 0;
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  position: relative;
  text-align: left;
  color: #4a4a49;
  width: 100%;
  letter-spacing: normal;
  transition: all 0.25s ease-in-out;
}

.Accordion dt:first-child .Accordion-trigger {
  border-radius: 0.3rem 0.3rem 0 0;
}

.Accordion-tick {
  margin-left: 1rem;
  color: #ef7026;
}

.Accordion-icon {
  border: solid #ef7026;
  border-width: 0 2px 2px 0;
  height: 1rem;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-60%) rotate(45deg);
  width: 1rem;
  transition: transform 0.2s ease-in-out;
}

.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
  border-color: #ef7026;
}

.Accordion-trigger[aria-expanded='true'] .Accordion-icon {
  transform: translateY(-50%) rotate(-135deg);
}

.Accordion-title {
  display: inline-block;
  font-weight: 500;
}

.Accordion-subtitle {
  display: block;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  font-weight: 300;
}

.Accordion-panel {
  margin: 0;
  font-weight: 300;
  height: auto;
  overflow: hidden;
  max-height: 500rem;
  transition: max-height 0.4s ease-in-out;
}

.Accordion-panel[aria-hidden='true'] {
  max-height: 0;
}

.animateIn {
  animation: accordionIn 0.2s normal ease-in-out both;
}

@keyframes accordionIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes accordionOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Accordion-inner {
  padding: 0 1.5rem 1rem;
}

.Accrodion-section {
  margin-bottom: 2.5rem;
}
