.StatusPanel {
}

.StatusPanel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.StatusPanel li {
  margin-bottom: 0.5rem;
}

.StatusPanel li .Tick {
  margin-right: 1rem;
  color: #ef7026;
}

.StatusPanel li .Cross {
  margin-right: 1.3rem;
  color: #ee3d48;
}
