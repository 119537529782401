.PaymentOptions ul {
  margin-top: 0;
  font-weight: 500;
}

.Button--add-another.is-active {
  background: #f58544;
  color: #fff;
}

.Button--add-another.is-active:hover {
  color: #fff;
}
