/* Overwrite React Select default stylings */

.Select-control {
  background-color: #f7f7f7;
  border: 1px solid transparent;
  border-radius: 0;
  color: #4a4a49;
}

.Select.is-open > .Select-control {
  background: #f7f7f7;
  border-color: #ef7026;
}

.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
  border: 1px solid #ef7026;
}

.Select--multi .Select-value {
  margin: 4px 0 0 4px;
  background-color: #fff;
  color: #ef7026;
  border-color: #ef7026;
}

.Select-option.is-focused {
  background-color: #ef7026;
  color: #fff;
}

.Select--multi .Select-value-icon {
  color: #ef7026;
  border-right-color: #ef7026;
}

.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: #ef7026;
  color: #fff;
}

.Select-value {
  font-weight: 400;
}

.Select-clear-zone:hover {
  color: #ef7026;
}

.Select-clear {
  font-size: 30px;
}

.Select.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #ef7026;
}

.Select.is-open > .Select-control .Select-arrow {
  border-bottom-color: #ef7026;
}

.Select-menu-outer {
  border-radius: 0;
  border-color: #ef7026;
}

.Select-input > input {
  height: 34px;
  padding: 0;
}
