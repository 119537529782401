.Loader {
  display: flex;
  align-items: center;
}

.Loader svg {
  width: 3rem;
  height: 3rem;
  padding: 0px;
  transform: rotate(90deg);
}

.Loader .circle {
  fill: transparent;
  stroke: #ef7026;
  stroke-width: 1.5rem;
  -webkit-animation: 1.5s circle infinite ease-in;
  animation: 1.5s circle infinite ease-in;
}

.Loader-text {
  margin-left: 1rem;
}

@keyframes circle {
  0% {
    stroke-dasharray: 0 400;
    stroke-dashoffset: 0;
  }
  25% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 400 400;
    stroke-dashoffset: -400;
  }
}
