.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 90%;
  max-height: 80%;
  max-width: 96rem;
  margin: 0 -50% 0 auto;
  background: #fff;
  overflow: auto;
  box-shadow: 0 1rem 4rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate(-50%, 0);
}

.modal-content--before-open,
.modal-content--before-close {
  transform: translate(-50%, 0);
}

.modal-content--after-open {
  transform: translate(-50%, -50%);
}

.modal-overlay,
.modal-content {
  opacity: 0;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.modal-content--after-open,
.modal-overlay--after-open {
  opacity: 1;
}

.modal-content--before-close,
.modal-overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal-header {
  position: relative;
  margin: 3rem;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
}

.modal-body {
  margin: 0 3rem 3rem;
}
