.SelectedList {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.SelectedList li {
  position: relative;
  padding: 0.5rem 2.5rem 0.5rem 3.5rem;
  transition: background-color 0.2s ease-out;
}

.SelectedList li .Alert {
  display: inline-block;
}

.SelectedList li .Button {
  width: 100%;
  text-align: left;
  color: #4a4a49;
  letter-spacing: normal;
  font-weight: 300;
}

.SelectedList li.is-invalid .Button {
  color: #ee3d48;
}

.SelectedList li:before {
  content: '✓';
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  background: #ef7026;
  border-radius: 100%;
  display: block;
  margin-right: 1rem;
  color: #fff;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 700;
  text-align: center;
}

.SelectedList li:hover,
.SelectedList li.is-active {
  background-color: #fde6d9;
}

.SelectedList li .Button--remove {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.SelectedList li .Button--remove .Cross svg {
  width: auto;
  height: 1.5rem;
}

.SelectedList li:hover .Button--remove {
  opacity: 0.75;
}

.SelectedList li:hover .Button--remove:hover {
  opacity: 1;
}
