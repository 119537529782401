.CollapsableError-path {
  font-size: 1.4rem;
  margin-left: 1rem;
  list-style-type: circle;
}

.CollapsableError-path-hidden {
  display: none;
}

.CollapsableError-clickable {
  cursor: zoom-in;
}

.CollapsableError-collapsable {
  cursor: zoom-out;
}

.CollapsableError-title::before {
  content: '';
  display: inline-block;
  margin-right: 1rem;
  border-right: 2px solid rgb(0, 0, 0);
  border-bottom: 2px solid rgb(0, 0, 0);
  width: 0.8rem;
  height: 0.8rem;
}

.CollapsableError-clickable::before {
  margin-bottom: 0.15rem;
  transform: rotate(-45deg);
}

.CollapsableError-collapsable::before {
  margin-bottom: 0.45rem;
  transform: rotate(45deg);
}

.CollapsableError {
  list-style: none;
}
