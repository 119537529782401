.PageContainer {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 2rem;
}

.Section {
  position: relative;
  margin-bottom: 4rem;
}

.Section:after {
  content: '';
  display: table;
  clear: both;
}

.Section .Button--cancel {
  position: absolute;
  left: 0;
  bottom: 0;
}

.PageNavGroup {
  text-align: right;
}

.PageNavGroup .Button {
  vertical-align: top;
  display: inline-block;
  margin-left: 1rem;
}
