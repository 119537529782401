html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  height: 100%;
  font-family: 'DIN Next Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  color: #4a4a49;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.375;
}

h1,
.PageHeader {
  margin: 0.6em 0;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
}

h2,
.SubHeader {
  margin: 0.5em 0;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.167;
}

h3 {
  margin: 0.4em 0;
  font-weight: 500;
  font-size: 1.8rem;
}

h4 {
  font-weight: 500;
}

* + p {
  margin-top: 1em;
}

ul,
ol {
  margin: 1em 1em 1em 0;
  padding-left: 1em;
  list-style-type: disc;
}

a {
  font-weight: 400;
  color: #ef7026;
}

a:not(.Button):hover {
  color: #f58544;
}

[hidden] {
  display: none !important;
}

.Text--error {
  color: #d03939;
  font-weight: 400;
}

hr {
  position: relative;
  margin: 3rem 0 4rem;
  border-top: 1px dashed #ef7026;
}

hr:after {
  content: '';
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: block;
  left: calc(50% - 1rem);
  top: -1rem;
  background: #fff;
  border-right: 1px dashed #ef7026;
  border-bottom: 1px dashed #ef7026;
  transform: rotate(45deg);
}
