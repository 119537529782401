.Alert {
  color: #ee3d48;
  font-weight: 400;
}

.Alert svg {
  color: currentColor;
  margin-right: 0.8rem;
  vertical-align: middle;
}
