.Progress {
  margin: 3rem 0;
}

.Progress-list {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.Progress-list:after {
  content: '';
  display: table;
  clear: both;
}

.Progress-list li {
  flex: 1;
  text-align: center;
  position: relative;
  padding: 0 10px;
  font-weight: 300;
  text-decoration: none;
}

.Progress-list li span {
  display: none;
}

@media (min-width: 655px) {
  .Progress-list li span {
    display: inline;
  }
}

.Progress-list li.is-active {
  font-weight: 500;
  color: #ee3d48;
}

/* Progress line */
.Progress-list li:before {
  content: '';
  width: 70%;
  height: 2px;
  background: #ef7026;
  position: absolute;
  left: -35%;
  bottom: 9px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  transform-origin: 0;
}

.Progress-list li.is-finished:before {
  animation: grow 0.3s ease-in-out;
}

@keyframes grow {
  0% {
    transform: scaleX(0);
  }
}

.Progress-list li:first-of-type:before {
  content: none;
}

/* Step dot */
.Progress-list li:after {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
  background: #fff;
  border: 2px solid #ef7026;
  border-radius: 100%;
  margin: 5px auto 0 auto;
  transition: background-color 0.3s ease-in-out;
}

.Progress-list li.is-finished:after {
  background: #fff;
  border: 2px solid #ef7026;
  border-radius: 100%;
  background-color: #ef7026;
}

.Progress-list li.is-active:after {
  border-color: #ee3d48;
  background-color: #ee3d48;
}
