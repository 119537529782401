.ValidationPanel {
  margin: 2rem 0;
  padding: 2rem 1rem;
  border: 1px dashed #ee3d48;
}

.ValidationPanel ul {
  margin-top: 0;
  margin-bottom: 0;
}
