.Callout {
  position: relative;
  margin: 1rem;
  padding: 2.5rem;
  border-radius: 4rem;
  background: #ef7026;
  color: #fff;
}

@media (min-width: 768px) {
  .Callout {
    border-radius: 6rem;
    padding: 2.5rem 4.5rem;
  }

  .Callout:before {
    content: '';
    position: absolute;
    display: block;
    color: #ef7026;
    font-size: 5rem;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .Callout--left {
    margin-left: 4rem;
  }
  .Callout--left:before {
    content: '◄';
    left: -3rem;
    top: 50%;
    top: calc(50% - 2.5rem);
    text-shadow: none;
  }

  .Callout--right {
    margin-right: 4rem;
  }
  .Callout--right:before {
    content: '►';
    right: -3rem;
    top: 50%;
    top: calc(50% - 2.5rem);
    text-shadow: none;
  }

  .Callout--bottom {
    margin-bottom: 4rem;
  }
  .Callout--bottom:before {
    content: '▼';
    left: calc(50% - 2.5rem);
    bottom: -3rem;
    text-shadow: none;
  }
}

.Callout h3 {
  margin: 0;
}

.Callout h3 + p {
  margin-top: 1rem;
}

.Callout a {
  color: #fff;
  text-decoration: none;
}

.Callout a:hover {
  color: #fff;
  text-decoration: underline;
}

.ReverseCallout {
  position: relative;
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 3rem;
  border: 2px solid #ef7026;
  background: #fff;
  color: #ef7026;
}

.ReverseCallout:after,
.ReverseCallout:before {
  content: ' ';
  position: absolute;
  right: 100%;
  top: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
}

.ReverseCallout:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 1.5rem;
  margin-top: -1.5rem;
}

.ReverseCallout:before {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ef7026;
  border-width: 1.8rem;
  margin-top: -1.8rem;
}

.ReverseCallout a {
  color: #ef7026;
}

.ReverseCallout a:hover {
  color: #ef7026;
}
